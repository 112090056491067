<template>
  <aside class="nav-container">
    <nav class="base-navigator">
      <base-navigator-nav-item
        v-for="(item, index) in props.items"
        :text="$t(`search_filters.${item.label}`)"
        :is-badge="currentIndex === index"
        @click="toggleNavigator(index, true)"
      />
      <slot
        name="major"
        :current-index="currentIndex"
        :toggle-outer-navigator="toggleNavigator"
      />
      <div class="rest-nav">
        <slot
          name="rest"
          :current-outer="currentOuter"
          :current-index="currentIndex"
          :toggle-inner-navigator="toggleNavigator"
          :toggle-outer-navigator="toggleOuterNavigator"
          :apply-inner="applyInner"
          :clear-inner="clearInner"
        />
      </div>
      <div class="base-navigator--controls">
        <base-button
          v-if="!$device.isMobile"
          :text="$t('search_filters.fr_clear')"
          size="sm"
          variant="dark"
          class="button"
          @click="
            () => {
              emit('reset-filters');
              resultFilters = {};
            }
          "
        />
        <!-- <base-button
          v-if="!$device.isMobile"
          text="Apply All"
          size="sm"
          variant="dark"
          class="button"
          @click="emit('change-filters', resultFilters)"
        /> -->
      </div>
    </nav>
  </aside>
</template>

<script setup lang="ts">
import type { IFilters } from "~/store/search_filters";
import { transformQueryToObject } from "~/utilities/helpers/query/transformQueryToObject";
interface Props {
  items?: Array<{ label: string; query: any; key: string }>;
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [
    {
      label: "fr_all",
      query: "",
      key: "",
    },
    {
      label: "fr_deals",
      query: "desc",
      key: "order[roi]",
    },
    {
      label: "fr_new",
      query: ["new"],
      key: "tag",
    },
  ],
});

interface Emits {
  (event: "change-filters", value: { [key: string]: string } | undefined): void;
  (event: "reset-filters"): void;
}

const router = useRouter();
const route = useRoute();

const emit = defineEmits<Emits>();
const currentIndex = ref<null | number>(null);
const currentOuter = ref(-1);
const resultFilters = ref<{ [key: string]: any }>({});

const toggleNavigator = (index: number, isMajor?: boolean) => {
  currentIndex.value = index;
  currentOuter.value = 0;
  if (isMajor) {
    const filter = {
      [props.items[index].key]: props.items[index].query,
    };
    emit("change-filters", filter);
  }
};
const toggleOuterNavigator = (id: number) => {
  currentOuter.value = id;
  currentIndex.value = null;
};

const applyInner = (item: IFilters, exclude?: boolean) => {
  if (!item) return;

  // Клонируем текущие значения фильтров
  const updatedFilters = {
    ...transformQueryToObject(route.query),
    ...resultFilters.value,
  };

  // Удаляем старые значения для ключа item.key
  delete updatedFilters[item.key];
  delete updatedFilters[`${item.key}[gte]`];
  delete updatedFilters[`${item.key}[lte]`];
  delete updatedFilters["irr"];
  delete updatedFilters["apr"];

  // Обновляем значения фильтров в зависимости от item.values или диапазонов
  if (item.values && item.values.length) {
    updatedFilters[item.key] = item.values;
  } else if (item.gte !== undefined || item.lte !== undefined) {
    updatedFilters[`${item.key}[gte]`] = item.gte;
    updatedFilters[`${item.key}[lte]`] = item.lte;
  }

  // Обновляем resultFilters.value
  resultFilters.value = updatedFilters;

  const plainResultFilters = JSON.parse(JSON.stringify(resultFilters.value));
  const newQuery = { ...route.query };

  // Удаляем старые значения из newQuery для текущего фильтра
  Object.keys(newQuery).forEach((key) => {
    if (key.startsWith(item.key)) {
      delete newQuery[key];
    }
  });

  // Добавляем новые значения в newQuery
  Object.keys(plainResultFilters).forEach((key) => {
    if (Array.isArray(plainResultFilters[key])) {
      plainResultFilters[key].forEach((q, index) => {
        newQuery[`${key}[${index}]`] = q;
      });
    } else {
      newQuery[key] = plainResultFilters[key];
    }
  });

  router.replace({ query: newQuery });
  emit("change-filters", resultFilters.value);
};

const clearInner = (item: IFilters) => {
  if (resultFilters.value[item.key]) {
    delete resultFilters.value[item.key];
  }
  if (typeof resultFilters.value[`${item.key}[gte]`] === "number") {
    delete resultFilters.value[`${item.key}[gte]`];
    delete resultFilters.value[`${item.key}[lte]`];
  }
  const plainResultFilters = JSON.parse(JSON.stringify(resultFilters.value));
  const newQuery = { ...plainResultFilters };
  Object.keys(newQuery).forEach((key) => {
    if (Array.isArray(newQuery[key])) {
      newQuery[key].forEach((q, index) => {
        newQuery[`${key}[${index}]`] = q;
      });
    } else {
      newQuery[key] = newQuery[key];
    }
  });
  router.replace({ query: newQuery });
  emit("change-filters", resultFilters.value);
};
</script>

<style scoped lang="scss">
.nav-container {
  position: relative;
  height: 100%;
  .base-navigator {
    height: fit-content;
    max-width: 240px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: flex-start;
    gap: 16px;
    padding: 24px 0px 24px 0px;
    position: sticky;
    top: 0;
    .rest-nav {
      width: 100%;
      padding-top: 40px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: flex-start;
      gap: 32px;
    }
    &--controls {
      min-width: 190px;
      display: grid;
      align-items: center;
      justify-items: center;
      justify-self: center;
      gap: 8px;
      padding-left: 40px;
      width: 100%;
      .base-button {
        width: 100%;
      }
    }
  }
}
</style>
